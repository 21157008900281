@import "../../../../styles/variables"
@import "../../../../styles/breakpoints"

.container
  background: var(--wt-white3-dt-black1)
  box-shadow: var(--box-shadow-31)
  width: 50%
  height: max-content
  min-height: 100vh
  border-radius: 0px 40px 40px 0px
  padding: 35px 35px 40px 0
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  box-sizing: border-box

.photoWrapper
  flex: 1
  margin-right: auto
  > svg
    height: 85%
    width: auto
    max-width: 530px
    margin-top: 40px

.photoWrapperDarkTheme
  svg
    height: 100vh
    min-height: 500px
    margin-top: -5vh
    margin-bottom: -4vh


.content
  max-width: 480px
  margin: 0 20px

.darkThemeContent
  max-width: 480px
  margin: auto 20px 0 20px

.header
  font-family: 'Microsoft YaHei'
  font-style: normal
  font-weight: 700
  font-size: 36px
  line-height: 143%
  letter-spacing: 0.05em

.item
  display: flex
  align-items: center
  &:not(:last-of-type)
    margin-bottom: 25px
  &:nth-child(2)
    max-width: 95%
  &:nth-child(3)
    width: 105%

.text
  font-weight: 400
  font-size: 18px
  line-height: 143%
  margin-left: 15px
  letter-spacing: 0.05em
  font-family: 'Microsoft YaHei'

.card
  width: 48px
  min-width: 48px
  height: 48px

.cardContent
  display: flex
  justify-content: center
  align-items: center
  svg
    & [fill="#BED5FF"]
      fill: var(--wt-blue15-dt-transparent)
    & [fill="#F1F3F6"]
      fill: var(--wt-white2-dt-transparent)
    & [fill="#F5F5F5"]
      fill: var(--wt-white10-dt-transparent)
    & [fill="#163878"]
      fill: var(--wt-blue3-dt-blue6)

@include media('<=Ltablet')
  .photoWrapper
    > svg
      width: 100%

@include media('>=desktop')
  .content
    margin-top: calc(50vh - 290px)
  .photoWrapper
    width: 100%
    > svg
      height: calc(100vh - (50vh - 290px) - 480px)
      width: auto
      max-width: 100%

@media (max-height: 900px)
  .content
    margin-top: 0
  .photoWrapper
    width: 100%
    > svg
      height: calc(100vh - 480px)
      width: auto
      max-width: 100%
      min-height: 250px