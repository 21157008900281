@import "../../../styles/variables"
@import "../../../styles/breakpoints"

.inner
  height: 100%
  overflow-y: scroll
  overflow-y: scroll
  -ms-overflow-style: none
  scrollbar-width: none
  display: flex
  flex-direction: column
  padding: 50px 50px 0
  min-height: 500px
  box-sizing: border-box
  width: 50%
  align-items: center
  margin-left: auto
  position: relative
  &::-webkit-scrollbar
    display: none

.top
  flex-grow: 1
  display: flex
  align-items: center
  justify-content: center
  flex-direction: column
.heading
  margin: 0
  text-transform: uppercase
  color: var(--wt-blue3-dt-white2)
   display: flex
  justify-content: space-between
  align-items: center
  flex-direction: column
  svg
    width: 135px
    height: 125px
  h1
    text-shadow: var(--text-shadow-5)
    margin-top: 15px

.bottom
  margin-top: 50px
  margin-bottom: 19px
  font-size: 0.857rem
  color: var(--wt-gray1-dt-blue5)
  text-decoration: none
  a
    font-size: 0.857rem
    color: $text-blue
    text-decoration: none
    margin: 0
    color: var(--wt-blue16-dt-blue1)


@include media('<=tablet')
  .inner
    width: 100%