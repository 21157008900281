@import "../../styles/variables"
@import "../../styles/breakpoints"

.wrapper
  display: flex
  max-width: 275px
  margin-bottom: 12px
  flex-direction: column
  &.minWidth
    min-width: 275px
    @include media('<=tablet')
      min-width: 250px
      width: 100%
    @include media('<=Lphone')
      min-width: 220px
      width: 100%
  &.fullWidth
    max-width: none
  &.noMargin
    margin-bottom: 0
  &.noAllMargin
    margin-bottom: 0
  &.h40
    .input
      max-height: 43px

.text
  padding-left: 10px
  text-shadow: var(--text-shadow-2)
  font-size: 1.143rem
  margin-bottom: 10px
  font-weight: 600
  color: var(--wt-blue3-dt-white2)

.label
  display: flex
  flex-direction: column
  width: 100%
  &.active, &.errorLabel, &.successLabel, &.disabled
    .inputWrapper::after
      opacity: 0
    .inputWrapper::before
      opacity: 1
      background: var(--border-19)
    .inputFocus
      &::after, &::before
        opacity: 1
    .input
      transform: none
  &.errorLabel
    .inputWrapper
      &::before
        background: var(--wt-red1-dt-red3)
    .error
      max-height: 150px
      font-size: 12px
      line-height: 143%
      margin-top: 5px
  &.successLabel
    .inputWrapper
      &::before
        background: var(--border-17)
  &.successTextLabel
    .success
      font-size: 12px
      line-height: 143%
      max-height: 50px
      margin-top: 5px

.inputWrapper
  width: 100%
  position: relative
  &::before, &::after
    content: ''
    position: absolute
    border-radius: var(--wt-15px-dt-8px)
    opacity: 1
    transition: opacity $animationTime
  &::before
    top: 0
    left: 0
    right: 0
    bottom: 0
  &::after
    background: var(--wt-white2-dt-black2)
    border: 3px solid var(--wt-white2-dt-black2)
    box-sizing: border-box
    box-shadow: var(--box-shadow-6)
    top: 1px
    left: 1px
    right: 1px
    bottom: 1px

.input
  padding: 15px
  margin: 1px
  width: calc(100% - 2px)
  box-sizing: border-box
  border-radius: var(--wt-15px-dt-8px)
  border: none
  background-color: transparent
  z-index: 2
  position: relative
  display: block
  outline: none
  overflow: hidden
  transform: translate($transformAmount, $transformAmount)
  color: var(--wt-blue3-dt-white2)
  transition: transform $animationTime
  color-scheme: var(--color-scheme)
  &:-webkit-autofill
    outline: none
    border: none
    -webkit-box-shadow: 0 0 0 30px var(--wt-white2-dt-black2) inset
    -webkit-text-fill-color: var(--wt-blue3-dt-white2)
  &[type = 'date']
    max-height: 46px
    &:invalid::-webkit-datetime-edit
      color: var(--wt-gray1-dt-blue5)
  &::placeholder
    color: var(--wt-gray1-dt-blue5)

.inputFocus
  position: absolute
  width: 100%
  height: 100%
  top: 0
  left: 0
  &::before, &::after
    content: ''
    position: absolute
    border-radius: var(--wt-15px-dt-8px)
    transition: opacity $animationTime
    opacity: 0
  &::before
    top: 0
    left: 0
    bottom: 0
    right: 0
    box-shadow: var(--box-shadow-35)
  &::after
    top: 1px
    left: 1px
    bottom: 1px
    right: 1px
    background-color: var(--wt-white2-dt-black2)

.error, .success
  position: relative
  z-index: 1
  max-height: 0
  transition: max-height $animationTime
  overflow: hidden

.error
  color: var(--wt-red1-dt-red2)

.success
  color: var(--wt-green1-dt-blue1)

.centered
  .text
    padding-left: 0

.centeredContent
  .input
    text-align: center

.widthMax
  max-width: 100%

.h100
  height: 100px

.noBorder
  .inputWrapper
    &::after, &::before
      opacity: 0