@import "src/styles/variables"
@import "src/styles/breakpoints"

.notificationInner
  display: flex
  justify-content: space-between
  align-items: center
  width: 100%

.notificationText
  display: flex
  align-items: center
  color: var(--wt-blue3-dt-white2)
  text-shadow: var(--text-shadow-2)
  font-size: 1.143rem
  font-weight: bold
  svg
    margin-right: 20px

.notificationButtons
  display: flex
  align-items: center


@include media('<=Lphone')
  .notificationInner
    flex-direction: column
    padding-left: 0
  .notificationText
    align-items: flex-start
    p
      margin-top: 0
      margin-left: 20px

@include media('<=phone')
  .notificationText
    > svg
      display: none
    p
      margin-left: 0