@import "../../../styles/breakpoints"

.container
  height: 100vh
  display: flex

.languages
  position: absolute
  top: 30px
  right: 30px

@include media('<=Ltablet')
  .languages
    right: 20px
    top: 20px

@include media('<=phone')
  .languages
    right: 16px
    top: 16px