@import "../../styles/variables"
@import "../../styles/breakpoints"

.label
  padding-left: 30px
  position: relative
  cursor: pointer
  user-select: none
  line-height: 20px
  font-size: 1rem
  display: flex
  align-items: center
  color: var(--wt-blue3-dt-white2)

.input
  position: absolute
  opacity: 0
  cursor: pointer
  height: 0
  width: 0
  &:checked ~ .checkmark
    .checkmarkFull
      opacity: 1

.indeterminate
  .checkmarkIndeterminate
    opacity: 1

.checkmark
  position: absolute
  top: 0
  left: 0
  height: 20px
  width: 20px
  display: flex
  justify-content: center
  align-items: center
  &::before, &::after
    content: ''
    position: absolute
    border-radius: 5px
  &::before
    top: -1px
    left: -1px
    width: 22px
    height: 22px
    background: var(--border-13)
  &::after
    top: 0
    left: 0
    width: 20px
    height: 20px
    background: var(--wt-white2-dt-black2)
    box-shadow: inset 2px 2px 5px var(--wt-white8-dt-black6), inset -3px -3px 7px var(--wt-white1-dt-black7)

.checkmarkFull, .checkmarkIndeterminate
  z-index: 3
  opacity: 0
  height: 100%
  border-radius: 5px
  width: 100%
  transition: opacity $animationTime
  display: flex
  justify-content: center
  align-items: center
  background-color: var(--wt-transparent-dt-blue1)
  svg
    width: 17px
    height: auto
    > path
      fill: var(--wt-blue3-dt-blue1)

.checkmarkIndeterminate
  position: absolute
  top: 50%
  left: 50%
  transform: translate(-50%, -50%)

@include media('>tablet')
  .big
    padding-left: 40px
    line-height: 30px
    height: 30px
    .checkmark
      width: 30px
      height: 30px
      &::before
        width: 32px
        height: 32px
      &::after
        width: 30px
        height: 30px
    .checkmarkFull, .checkmarkIndeterminate
      svg
        width: 21px