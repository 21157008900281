@import "src/styles/variables"
@import "src/styles/breakpoints"

.checkbox
  display: flex
  justify-content: space-between
  align-items: center
  margin-bottom: -7px
  a
    color: var(--wt-blue3-dt-blue1)

.button
  margin-top: 30px

.additional
  line-height: 1.43
  color: var(--wt-gray1-dt-blue5)
  margin: 0
  margin-top: 15px
  a
    color: var(--wt-blue3-dt-blue1)
    margin-left: 5px

.description
  line-height: 1.43
  color: var(--wt-blue3-dt-white2)
  margin-top: 28px
  margin-bottom: 33px
  font-size: 1.143rem
  text-align: center

.input
  min-width: 275px

.error
  color: var(--wt-red1-dt-red2)
  text-align: center
  margin-top: 10px