@import "src/styles/variables"

.button
  margin-top: 30px

.description, .identity
  line-height: 1.43
  color: var(--wt-blue3-dt-white2)
  font-size: 1.143rem
  text-align: center
  margin: 0
  max-width: 400px

.identity
  margin-top: 28px
  margin-bottom: 15px

.description
  margin-bottom: 33px

.input
  min-width: 275px

.error
  color: var(--wt-red1-dt-red2)
  text-align: center
  margin-top: 10px